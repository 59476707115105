const convertTime = (time, addStatus) => {
  const currentDate = new Date();
  const targetTime = new Date(currentDate);

  if (addStatus) {
    targetTime.setDate(currentDate.getDate() + 1);
  }

  targetTime.setHours(Number(time), 0, 0, 0);

  const year = targetTime.getUTCFullYear();
  const month = String(targetTime.getUTCMonth() + 1).padStart(2, "0");
  const day = String(targetTime.getUTCDate()).padStart(2, "0");
  const hours = String(targetTime.getUTCHours()).padStart(2, "0");
  const minutes = String(targetTime.getUTCMinutes()).padStart(2, "0");
  const seconds = String(targetTime.getUTCSeconds()).padStart(2, "0");

  const formattedTargetTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

  return formattedTargetTime;
};

let bedTime = convertTime("22", false);
let wakeupTime = convertTime("06", true);

export const QuestionData = {
  types: ["Activity", "Nutrition", "Sleep", "Mental Wellbeing", "Lifestyle"],
  questions: [
    {
      id: 1,
      heading: "Nutrition",
      input_type: "radio",
      type: "aic_program",
      sub_type: "food_preference",
      question: "What is your food preference?",
      question_desc: "",
      options: [
        {
          label:
            "Vegetarian : Primarily plant-based, avoiding meat, poultry, and seafood",
          value: "vegetarian",//"Vegetarian",
          icon:"1-1"
        },
        {
          label:
            "Non-Vegetarian : Consumes meat, poultry, seafood, and other animal products along with plant-based foods",
          value: "non_vegetarian",//"Non-Vegetarian",
          icon:"1-2"
        },
        {
          label:
            "Pescatarian : Avoids meat and poultry but includes seafood in their diet",
          value: "pescatarian",//"Pescatarian",
          icon:"1-3"
        },
        {
          label:
            "Flexitarian : Primarily plant-based with occasional meat, poultry, or seafood. A flexible approach to vegetarianism",
          value: "flexitarian",//"Flexitarian",
          icon:"1-4"
        },
        {
          label:
            "Vegan : Exclusively plant-based, avoiding all animal products including dairy and eggs",
          value: "vegan",//"Vegan",
          icon:"1-5"
        },
        
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
    },
    {
      id: 2,
      heading: "Nutrition",
      input_type: "checkbox",
      type: "aic_program",
      sub_type: "type_of_meat",
      question: "Which type of meat do you consume?",
      question_desc:
        "Please select all that apply to help us tailor your recommendations.",
      options: [
        {
          label: "Beef",
          value: "Beef",
          icon:"2-1"
        },
        {
          label: "Chicken",
          value: "Chicken",
          icon:"2-2"
        },
        {
          label: "Pork",
          value: "Pork",
          icon:"2-3"
        },
        {
          label: "Mutton",
          value: "Mutton",
          icon:"2-7"
        },
        {
          label: "Turkey",
          value: "Turkey",
          icon:"2-4"
        },
        {
          label: "Fish",
          value: "Fish",
          icon:"2-5"
        },
        {
          label: "Shellfish",
          value: "Shellfish",
          icon:"2-6"
        },
      ],
      data: {
        value: [],
      },
      is_required: true,
      is_score: false,
      parent_key: 1,
      allowedValues: ["non_vegetarian","flexitarian"],//["Non-Vegetarian", "Flexitarian"],
    },
    {
      id: 3,
      heading: "Nutrition",
      input_type: "checkbox",
      type: "aic_program",
      sub_type: "cuisine_preference",
      question: "What is you cuisine preference?",
      question_desc: "(Select all that applies)",
      options: [
        {
          label: "North Indian",
          value: "North Indian",
          icon:"3-1"
        },
        {
          label: "South Indian",
          value: "South Indian",
          icon:"3-2"
        },
        {
          label: "Jain",
          value: "Jain",
          icon:"3-3"
        },
        {
          label: "Mediterranean",
          value: "Mediterranean",
          icon:"3-4"
        },
        {
          label: "Continental",
          value: "Continental",
          icon:"3-5"
        },
        {
          label: "Chinese",
          value: "Chinese",
          icon:"3-6"
        },
        {
          label: "Arabian",
          value: "Arabian",
          icon:"3-7"
        },
        {
          label: "Asian",
          value: "Asian",
          icon:"3-8"
        },
        {
          label: "Japanese",
          value: "Japanese",
          icon:"3-9"
        },
      ],
      data: {
        value: [],
      },
      is_required: true,
      is_score: false,
    },
    {
      id: 4,
      heading: "Nutrition",
      input_type: "radio",
      type: "aic_program",
      sub_type: "is_dairy_preferred",
      question: "Do you consume milk and milk products?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "true",
          icon:"yes"
        },
        {
          label: "No",
          value: "false",
          icon:"no"
        },
      ],
      data: {
        value: "",
      },
      is_boolean: true,
      is_required: true,
      is_score: false,
    },
    {
      id: 5, //score
      heading: "Nutrition",
      input_type: "radio",
      type: "aic_program",
      sub_type: "daily_eating_habit",
      question: "How is your daily eating habits?",
      question_desc: "",
      options: [
        {
          label: "Primarily Home Cooked Meals",
          value: "Primarily Home Cooked Meals",
          score: 5,
          icon:"4-1"
        },
        {
          label: "Occasional Snacker",
          value: "Occasional Snacker",
          score: 4,
          icon:"4-2"
        },
        {
          label: "Often dining out",
          value: "Often dining out",
          score: 2,
          icon:"4-3"
        },
        {
          label: "Regular Junk Food Consumer",
          value: "Regular Junk Food Consumer",
          score: 1,
          icon:"4-4"
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 6, //score
      heading: "Nutrition",
      input_type: "radio",
      type: "aic_program",
      sub_type: "diet_experience",
      question: "What is your past experience with diets?",
      question_desc: "",
      options: [
        {
          label: "Tried and found what works",
          value: "Tried and found what works",
          score: 5,
        },
        {
          label: "Tried various diets, unsure of the best",
          value: "Tried various diets, unsure of the best",
          score: 3,
        },
        {
          label: "Tried them all, hard to sustain",
          value: "Tried them all, hard to sustain",
          score: 2,
        },
        {
          label: "None",
          value: "None",
          score: 0,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 7, //score
      heading: "Nutrition",
      input_type: "radio",
      type: "aic_program",
      sub_type: "nutrition_tracking_experience",
      question:
        "How familiar are you with tracking calories or macronutrients and micronutrients?",
      question_desc: "",
      options: [
        {
          label: "Very familiar, successful tracker",
          value: "Very familiar, successful tracker",
          score: 5,
        },
        {
          label: "Tracked a bit, unsure of my current food intake",
          value: "Tracked a bit, unsure of my current food intake",
          score: 3,
        },
        {
          label: "Never tracked, need guidance",
          value: "Never tracked, need guidance",
          score: 2,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 8,
      heading: "Nutrition",
      input_type: "checkbox",
      type: "aic_program",
      sub_type: "allergy",
      question: "Do you have any food allergies or intolerances?",
      question_desc: "(Select all that applies)",
      options: [
        {
          label: "Milk : (dairy allergy)",
          value: "Milk",
          allowedValues:["non_vegetarian","vegetarian","flexitarian"],//["Non-Vegetarian", "Vegetarian", "Flexitarian"],
          icon:"5-1"
        },
        {
          label: "Eggs",
          value: "Eggs",
          allowedValues: ["non_vegetarian","vegetarian","flexitarian"],//["Non-Vegetarian", "Flexitarian"],
          icon:"5-2"
        },
        {
          label: "Peanuts",
          value: "Peanuts",
          allowedValues:["non_vegetarian","vegetarian","pescatarian","flexitarian","vegan"], //["Non-Vegetarian", "Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"5-3"
        },
        {
          label: "Tree nuts : (such as almonds, walnuts, cashews, etc.) ",
          value: "Tree nuts",
          allowedValues:["non_vegetarian","vegetarian","pescatarian","flexitarian","vegan"], //["Non-Vegetarian", "Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"5-4"
        },
        {
          label: "Soy",
          value: "Soy",
          allowedValues:["non_vegetarian","vegetarian","pescatarian","flexitarian","vegan"], //["Non-Vegetarian", "Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"5-5"
        },
        {
          label: "Wheat : (gluten allergy or celiac disease)",
          value: "Wheat",
          allowedValues:["non_vegetarian","vegetarian","pescatarian","flexitarian","vegan"], //["Non-Vegetarian", "Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"5-6"
        },
        {
          label: "Fish",
          value: "Fish",
          allowedValues:["non_vegetarian", "pescatarian", "flexitarian", "vegan"], //["Non-Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"5-7"
        },
        {
          label: "Shellfish : (such as shrimp, crab, lobster, etc.)",
          value: "Shellfish",
          allowedValues:["non_vegetarian", "pescatarian", "flexitarian"], //["Non-Vegetarian", "Pescatarian", "Flexitarian"],
          icon:"5-8"
        },
        {
          label: "None",
          value: "None",
          allowedValues:["non_vegetarian", "vegetarian", "pescatarian", "flexitarian", "vegan"], //["Non-Vegetarian", "Vegetarian", "Pescatarian", "Flexitarian", "Vegan"],
          icon:"no"
        },
      ],
      data: {
        value: [],
      },
      parent_key: 1,
      parent_answer: "",
      is_none:true,
      is_required: false,
      is_score: false,
      is_option_based: true,
    },
    {
      id: 9, //score
      heading: "Activity",
      input_type: "radio",
      type: "ha_profile",
      sub_type: "activity_level",
      question: "How does your typical day look like?",
      question_desc: "",
      options: [
        //check
        {
          label: "Sedentary",
          value: "sedentary",
          score: 1,
          icon:"6-1"
        },
        {
          label: "Lightly Active",
          value: "light",
          score: 2,
          icon:"6-2"
        },
        {
          label: "Moderately Active",
          value: "moderate",
          score: 4,
          icon:"6-3"
        },
        {
          label: "Very Active",
          value: "high",
          score: 5,
          icon:"6-4"
        },
        // {
        //   label: "Very Active",
        //   value: "very_high",
        //   score: 5,
        // },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 10, //score
      heading: "Activity",
      input_type: "radio",
      type: "aic_program",
      sub_type: "n_workout_days_per_week",
      question: "How often do you prefer to workout per week?",
      question_desc: "",
      options: [
        //check
        {
          label: "3 days",
          value: "3",
          score: 1,
        },
        {
          label: "4 days",
          value: "4",
          score: 2,
        },
        {
          label: "5 days",
          value: "5",
          score: 4,
        },
        {
          label: "6 days",
          value: "6",
          score: 5,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 11, //score
      heading: "Activity",
      input_type: "radio",
      type: "aic_program",
      sub_type: "workout_duration_per_day",
      question: "How long do you prefer to workout each session?",
      question_desc: "",
      options: [
        {
          label: "15 mins",
          value: "900",
          score: 1,
        },
        {
          label: "30 mins",
          value: "1800",
          score: 2,
        },
        {
          label: "45 mins",
          value: "2700",
          score: 4,
        },
        {
          label: "60 mins",
          value: "3600",
          score: 5,
        },
      ], //Secs
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 12,
      heading: "Activity",
      input_type: "radio",
      type: "aic_program",
      sub_type: "preferred_workout_time",
      question: "When do you prefer to workout?",
      question_desc: "",
      options: [
        {
          label: "Morning",
          value: "morning",
          icon:"7-1"
        },
        {
          label: "Afternoon",
          value: "afternoon",
          icon:"7-2"
        },
        {
          label: "Evening",
          value: "evening",
          icon:"7-3"
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
    },
    {
      id: 13, //score
      heading: "Activity",
      input_type: "radio",
      type: "aic_program",
      sub_type: "strength_training_familiarity",
      question: "What is you familiarity with strength training?",
      question_desc: "",
      options: [
        {
          label: "Love it! I regularly strength train",
          value: "Love it! I regularly strength train",
          score: 5,
        },
        {
          label: "Familiar but inconsistent",
          value: "Familiar but inconsistent",
          score: 3,
        },
        {
          label: "Never tried, need guidance to start",
          value: "Never tried, need guidance to start",
          score: 2,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 14,
      heading: "Activity",
      input_type: "checkbox",
      type: "aic_program",
      sub_type: "equipments_available",
      question: "Equipments available",
      question_desc: "(Select all that applies)",
      options: [
        {
          label: "Dumbbells",
          value: "dumbbells",
          icon:"8-1"
        },
        {
          label: "Kettlebells",
          value: "kettlebells",
          icon:"8-2"
        },
        {
          label: "Resistance bands",
          value: "resistance bands",
          icon:"8-3"
        },
        {
          label: "None",
          value: "None",
          icon:"no"
        },
      ],
      data: {
        value: [],
      },
      is_none:true,
      is_required: false,
      is_score: false,
    },
    {
      id: 15, //score
      heading: "Sleep",
      input_type: "radio",
      type: "aic_program",
      sub_type: "sleep_hygiene",
      question: "How would you rate your sleep hygiene",
      question_desc: "Sleep hygiene refers to both your sleep environment and behavior.",
      options: [
        {
          label: "Excellent routine, sleep like a dream!",
          value: "Excellent routine, sleep like a dream!",
          score: 5,
          icon:"smiley-1"
        },
        {
          label: "Room for improvement, occasional distractions",
          value: "Room for improvement, occasional distractions",
          score: 3,
          icon:"smiley-2"
        },
        {
          label: "Needs work, struggling with sleep habits",
          value: "Needs work, struggling with sleep habits",
          score: 2,
          icon:"smiley-3"
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 16,
      heading: "Sleep",
      input_type: "timepicker",
      type: "preference",
      sub_type: "weekday_sleep_routine_bed_time", //check
      question: "What time do you usually go to bed on weekdays?",
      question_desc: "",
      options: [],
      data: {
        value: bedTime,
      },
      is_add: false,
      is_required: true,
      is_score: false,
    },
    {
      id: 17,
      heading: "Sleep",
      input_type: "timepicker",
      type: "preference",
      sub_type: "weekday_sleep_routine_wakeup_time",
      question: "What time do you usually wake up on weekdays?",
      question_desc: "",
      options: [],
      data: {
        value: wakeupTime,
      },
      is_add: true,
      is_required: true,
      is_score: false,
    },
    {
      id: 18,
      heading: "Sleep",
      input_type: "timepicker",
      type: "preference",
      sub_type: "weekend_sleep_routine_bed_time",
      question: "What time do you usually go to bed on weekends?",
      question_desc: "",
      options: [],
      data: {
        value: bedTime,
      },
      is_add: false,
      is_required: true,
      is_score: false,
    },
    {
      id: 19,
      heading: "Sleep",
      input_type: "timepicker",
      type: "preference",
      sub_type: "weekend_sleep_routine_wakeup_time",
      question: "What time do you usually wakeup on weekends?",
      question_desc: "",
      options: [],
      data: {
        value: wakeupTime,
      },
      is_add: true,
      is_required: true,
      is_score: false,
    },
    {
      id: 20,
      heading: "Sleep",
      input_type: "radio",
      type: "aic_program",
      sub_type: "",
      question:
        "Let's make your sleep schedule perfect! Would you like to set your ideal bedtime or wakeup time?",
      question_desc: "",
      options: [
        {
          label: "Bedtime",
          value: "Bedtime",
          icon:"9-1"
        },
        {
          label: "Waketime",
          value: "Waketime",
          icon:"9-2"
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: false,
      is_change_subtype: true,
      is_send_to_api: false,
      is_remove:true
    },
    {
      id: 21,
      heading: "Sleep",
      input_type: "timepicker",
      type: "aic_program",
      sub_type: "bed_time_goal",
      question: "Set your ideal Bedtime",
      question_desc: "",
      options: [],
      data: {
        value: bedTime,
      },
      is_add: true,
      is_required: true,
      is_score: false,
      parent_key: 20,
      allowedValues: ["Bedtime"],
    },
    {
      id: 22,
      heading: "Sleep",
      input_type: "timepicker",
      type: "aic_program",
      sub_type: "wakeup_time_goal",
      question: "Set your ideal Waketime",
      question_desc: "",
      options: [],
      data: {
        value: wakeupTime,
      },
      is_add: true,
      is_required: true,
      is_score: false,
      parent_key: 20,
      allowedValues: ["Waketime"],
    },
    {
      id: 23, //score
      heading: "Sleep",
      input_type: "radio",
      type: "aic_program",
      sub_type: "sleep_satisfaction",
      question: "How satisfied are you with your sleep?",
      question_desc: "",
      options: [
        {
          label: "Fully Satisfied",
          value: "Fully Satisfied",
          score: 5,
          icon:"smiley-1"
        },
        {
          label: "Somewhat Satisfied",
          value: "Somewhat Satisfied",
          score: 3,
          icon:"smiley-2"
        },
        {
          label: "Not Satisfied",
          value: "Not Satisfied",
          score: 2,
          icon:"smiley-3"
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 24, //score
      heading: "Sleep",
      input_type: "radio",
      type: "aic_program",
      sub_type: "sleep_wakeup_refreshment",
      question: "Do you wake up refreshed?",
      question_desc: "",
      options: [
        {
          label: "Always",
          value: "Always",
          score: 5,
        },
        {
          label: "Sometimes",
          value: "Sometimes",
          score: 3,
        },
        {
          label: "Rarely",
          value: "Rarely",
          score: 2,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 25, //score
      heading: "Sleep",
      input_type: "radio",
      type: "aic_program",
      sub_type: "sunlight_upon_wakeup",
      question: "How much sunlight do you get?",
      question_desc: "",
      options: [
        {
          label: "Morning sun first thing and outdoor breaks",
          value: "Morning sun first thing and outdoor breaks",
          score: 5,
        },
        {
          label: "Some natural light, but could use more",
          value: "Some natural light, but could use more",
          score: 3,
        },
        {
          label: "Mostly on artificial light",
          value: "Mostly on artificial light",
          score: 2,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 26, //score
      heading: "Mental Wellbeing",
      input_type: "radio",
      type: "aic_program",
      sub_type: "wellness_motivation_frequency",
      question: "How often do you seek motivation for your wellness routine?",
      question_desc: "",
      options: [
        {
          label:
            "All the time : I constantly seek motivation to stick to my wellness routine",
          value: "All the time",
          score: 2,
        },
        {
          label:
            "Now and then : Most days I'm self-driven, but there are times when I could use a little push",
          value: "Now and then",
          score: 3,
        },
        {
          label:
            "Hardly Ever : I'm intrinsically motivated and seldom need external encouragement",
          value: "Hardly Ever",
          score: 5,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 27,
      heading: "Mental Wellbeing",
      input_type: "radio",
      type: "aic_program",
      sub_type: "wellness_bother_frequency",
      question:
        "How many times in the past month you've been bothered by low feeling, stress or sadness",
      question_desc: "",
      options: [
        {
          label: "Everyday",
          value: "Everyday",
          score: 1,
        },
        {
          label: "Occasionally",
          value: "Occasionally",
          score: 2,
        },
        {
          label: "Rarely",
          value: "Rarely",
          score: 3,
        },
        {
          label: "Fortnight",
          value: "Fortnight",
          score: 4,
        },
        {
          label: "Once a month",
          value: "Once a month",
          score: 5,
        },
      ],
      data: {
        value: "",
      },
      is_required: true,
      is_score: true,
    },
    {
      id: 28,
      heading: "Lifestyle",
      input_type: "radio",
      type: "ha_profile",
      sub_type: "is_pregnant",
      question: "Are you pregnant?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "true",
          icon:"yes"
        },
        {
          label: "No",
          value: "false",
          icon:"no"
        },
      ],
      data: {
        value: "",
      },
      is_alt_icon: true,
      is_boolean: true,
      is_gender: true,
      is_required: false,
      is_score: false,
      allowedValues: ["female"],
    },
    {
      id: 29,
      heading: "Lifestyle",
      input_type: "checkbox",
      type: "aic_program",
      sub_type: "food_special_case",
      question: "Have you been diagnosed with any medical condition?",
      question_desc: "(Select all that applies)",
      options: [
        {
          label: "Diabetes",
          value: "diabetes",
        },
        {
          label: "Hypertension (Blood Pressure)",
          value: "hypertension",
        },
        {
          label: "PCOS / PCOD",
          value: "pcos / pcod",
        },
        {
          label: "Thyroid",
          value: "hyperthyroid",
        },
        {
          label: "Asthama",
          value: "asthama",
        },{
          label: "None",
          value: "None"
        },
      ],
      data: {
        value: [],
      },
      is_none:true,
      is_required: false,
      is_score: false,
    },
    {
      id: 30,
      heading: "Lifestyle",
      input_type: "radio",
      type: "ha_profile",
      sub_type: "smoke",
      question: "Do you smoke?*",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "true",
          icon:"yes"
        },
        {
          label: "No",
          value: "false",
          icon:"no"
        },
      ],
      data: {
        value: "",
      },
      is_boolean: true,
      is_required: true,
      is_score: false,
    },
    {
      id: 31,
      heading: "Lifestyle",
      input_type: "radio",
      type: "ha_profile",
      sub_type: "alcohol",
      question: "Do you consume alcohol?",
      question_desc: "",
      options: [
        {
          label: "Yes",
          value: "true",
          icon:"yes"
        },
        {
          label: "No",
          value: "false",
          icon:"no"
        },
      ],
      data: {
        value: "",
      },
      is_boolean: true,
      is_required: true,
      is_score: false,
    },
  ],
  score: [    
    {
      heading: "Activity",
      type: "aic_program",
      sub_type: "activity_score",
      length: 0,
      data: {
        value: 0,
      },
    },
    {
      heading: "Nutrition",
      type: "aic_program",
      sub_type: "nutrition_score",
      length: 0,
      data: {
        value: 0,
      },
    },
    {
      heading: "Sleep",
      type: "aic_program",
      sub_type: "sleep_score",
      length: 0,
      data: {
        value: 0,
      },
    },
    {
      heading: "Mental Wellbeing",
      type: "aic_program",
      sub_type: "mental_health_score",
      length: 0,
      data: {
        value: 0,
      },
    },
    // {
    //   heading: "Overall Score",
    //   type: "aic_program",
    //   sub_type: "overall_score",
    //   length:0,
    //   data: {
    //     value: 0,
    //   },
    // },
  ],
};
